<template>
  <ion-page>
    <Header title="Kupon Undian" />
    <ion-content :fullscreen="true">
      <div class="container" v-if="claim">
        <Coupon :coupon="claim.coupon" :status="false" />
        <!-- {{ claim }} -->
        <div class="a1s text-sm mt-2">Kode kupon</div>
        <div class="code bold mb-2">
          {{ claim.coupon.coupon_number }}
        </div>
        <div class="divinder"></div>
        <div class="detail">
          <div class="x-row">
            <div class="label">Nama</div>
            <div class="value">{{ claim.shipping_name }}</div>
          </div>
          <div class="x-row">
            <div class="label">No. Handphone</div>
            <div class="value">{{ claim.shipping_phone }}</div>
          </div>
          <div class="x-row">
            <div class="label">Alamat</div>
            <div class="value">
              <div class="x-line">
                {{ claim.shipping_address.address }}
              </div>
              <div class="x-line">
                {{ claim.shipping_address.city }}
              </div>
              <div class="x-line">
                {{ claim.shipping_address.postal_code }}
              </div>
            </div>
          </div>
          <div class="x-row">
            <div class="label">Status</div>
            <div class="value">
              {{ status[claim.status] }}
            </div>
            <div class="value">
              {{ claim.reason }}
            </div>
          </div>
          <div class="x-row" v-if="claim.resi_number">
            <div class="label">No. Resi</div>
            <div class="valu">{{ claim.provider }} / {{ claim.resi_number }} </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import Coupon from "@/components/coupon";
import Header from '@/components/headerPage'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header,
    Coupon
  },
  data () {
    return {
      claim: null,
      status: ["Di Batalkan", "Sedang Diproses", "Di Tunda", "Berhasil"],
    }
  },
  created() {
    this.getClaim()
  },
  watch: {
    '$route': 'getClaim'
  },
  methods: {
    async getClaim () {
      let id = this.$route.params.id
      if(id) {
        this.claim = await this.$api.get(`/api/claim/${id}`).then(res => res.data)
      }
    }
  }
})
</script>

<style lang="scss">
.x-row {
  margin-bottom: .6rem;
  .label {
    font-size: 14px;
    color: #545454;
  }
}
</style>
