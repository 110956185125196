<template>
<div class="coupon" @click="detail(coupon)">
  <div class="left">
    <ion-avatar>
      <img :src="coupon.event.cover">
    </ion-avatar>
  </div>
  <div class="right">
    <div class="a12">{{ coupon.event.name }}</div>
    <div class="code">Nomor Kupon: {{ coupon.coupon_number }}</div>
    <template v-if="status">
      <slot></slot>
      <ion-badge color="success" v-if="coupon.event.active && ! coupon.used">
        Diundi pada {{ $filters.dateFormat(coupon.event.finish_at) }}
      </ion-badge>
      <ion-badge color="medium" v-if="coupon.fail">
        Meleset
      </ion-badge>
      <ion-badge color="dark" v-if="coupon.must_claim">
        <div  class="flex align-center">
          Klaim Hadiah sekarang
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 12L10 8L6 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </ion-badge>
    </template>
  </div>
</div>
</template>

<script>
import { IonBadge, IonAvatar } from '@ionic/vue'
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    coupon: Object,
    status: {
      type: Boolean,
      default: true
    }
  },
  components: {
    IonBadge,
    IonAvatar
  },
  methods: {
    detail(c) {
      this.$emit('open', c)
    }
  }
})
</script>

<style lang="scss">
.coupon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // border: 1px dashed #ddd;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 1rem;
  flex-wrap: nowrap;

  .left, .right {
    padding: 1rem;
  }
  .left {
    border-right: 1px dashed #ddd;
  }

  .a12 {
    font-weight: bold;
  }
  .code {
    font-size: 12px;
    color: #545454;
    margin: 5px 0;
  }
  .ion-color {
    color: #fff !important;
  }
}
</style>
